#wrapper {
    background-color: var(--foreground-color);
    padding: 30px var(--content-padding) 20px var(--content-padding);
    border-top: 1px solid var(--foreground-color-lighter);
    text-align: center;
}

#wrapper p {
    color: var(--text-color-darker);
    font-family: var(--font-heading);
    font-size: .9em;
}

#twitter {
    margin-top: 15px;
}

#twitter a i {
    color: var(--text-color-darker);
    cursor: pointer;
    transition: .3s ease;
    font-size: 1.2em;
}

#twitter a i:hover {
    color: var(--accent-color);
}