#header {
    background-color: var(--foreground-color);
    width: 100%;
    z-index: 11;
    top: 0;
    backdrop-filter: blur(10px);
}

.navbar {
    padding-bottom: 10px;
    font-family: var(--font-heading);
    font-size: 1.05em;
    font-weight: 400;
    height: 55px;
}

.mainNav {
    list-style-type: none;
    display: none;
    margin-top: -2px;
    background-color: var(--foreground-color);
    padding-bottom: 15px;
}

.mainNav li > a {
    text-decoration: none;
}

.navLinks,
.logo {
    text-decoration: none;
    color: var(--text-color);
}

.mainNav li {
    text-align: center;
    margin: 5px auto 15px auto;
}

.logo {
    display: inline-block;
    font-size: 1.2em;
    margin-top: 13px;
    margin-left: 20px;
}

.logo img {
    width: 36px;
}

.navbarToggle {
    position: absolute;
    top: 13px;
    right: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
}

.active {
    display: block;
}

@media screen and (min-width: 768px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        height: 80px;
        align-items: center;
    }

    .logo img {
        width: 40px;
        margin-top: 16px;
    }

    .mainNav {
        display: flex;
        margin-right: var(--content-padding);
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 0px;
        background-color: transparent;
        padding-bottom: 0px;
    }

    .mainNav li {
        margin: 0;
    }

    .navLinks {
        margin-left: 45px;
        transition: all 0.25s;
        position: relative;
    }

    .navLinks::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -10px;
        left: 0;
        visibility: hidden;
        background-color: var(--accent-color);
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
    }

    .navActive::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -10px;
        left: 0;
        visibility: visible;
        background-color: var(--accent-color);
        transition: all 0.3s ease-in-out 0s;
    }

    .navLinks:hover::before {
        visibility: visible;
        transform: scaleX(1);
    }

    .logo {
        margin-top: -10px;
        margin-left: var(--content-padding);
        font-size: 1.4em;
    }

    .navbarToggle {
        display: none;
    }

    .navLinks:hover::before {
        color: var(--text-color);
    }

    /* .navLinks:hover::after {
        color: var(--text-color);
    } */
}