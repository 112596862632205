#hero {
    background-image: url(../assets/images/hero.png);
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#easterEgg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #181818cc;
    display: flex;
    align-items: center;
    justify-content: center;
}

#easterEgg iframe {
    display: block;
}

@media screen and (max-width: 768px) {
    #hero {
        height: 80px;
    }
}