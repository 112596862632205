#wrapper {
    background-color: var(--foreground-color);
    padding: 10px var(--content-padding) 50px var(--content-padding);
}

.subheading {
    font-family: 'Raleway', sans-serif;
    font-size: 1.2em;
    font-weight: 600;
}

.content {
    font-family: var(--font-primary);
    font-weight: 300;
    margin-top: 25px;
    font-size: 1.2em;
    letter-spacing: 0.5px;
    line-height: 1.3em;
}

.masonryGrid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.masonryGridColumn {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.masonryGridColumn > div {
    /* change div to reference your elements you put in <Masonry> */
    background: var(--foreground-color-lighter);
    margin-bottom: 15px;
    /* padding: 20px 20px 15px 20px; */
    border-radius: 1px;
    text-align: center;
    height: auto;
}

.masonryGridColumn > div > img {
    width: 100%;
    cursor: pointer;
    display: block;
}

.masonryGridColumn p {
    color: var(--text-color-darker);
    font-family: var(--font-heading);
    font-weight: 400;
    font-size: .75em;
    margin-top: 4px;
}

@media screen and (max-width: 768px) {
    #wrapper {
        padding: 50px 30px;
    }

    .content {
        font-size: 1.1em;
    }
}
