.row {
    display: flex;
    flex-flow: row wrap;
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    height: inherit;
}

.row.expanded {
    max-width: none;
}

.flex-start {
    justify-content: flex-start;
}

.center {
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.space-evenly {
    justify-content: space-evenly;
}

.align-flex-end {
    align-items: flex-end;
}

.align-flex-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-stretch {
    align-items: stretch;
}

.align-baseline {
    align-items: baseline;
}

.column {
    flex: 1 1 0px;
    box-sizing: border-box;
}

.column > .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
}

.sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

@media screen and (min-width: 40em) {
    .md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (min-width: 64em) {
    .lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}