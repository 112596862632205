@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&family=Open+Sans:wght@300;400&family=Raleway:wght@300;400;500&display=swap');

:root {
    --accent-color: #BE1244;
    --background-color: #121212;
    --foreground-color: #181818;
    --foreground-color-lighter: #282828;
    --foreground-color-lightest: #404040;
    --text-color: #FFFFFF;
    --text-color-darker: #B3B3B3;

    --font-primary: 'Source Sans Pro', sans-serif;
    --font-heading: 'Raleway', sans-serif;

    --content-width: 1200px;
    --mobile-threshold: 768px;
    --content-padding: 150px;
}

* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
}