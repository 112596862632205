#wrapper {
    background-color: var(--foreground-color);
    padding: 50px var(--content-padding) 10px var(--content-padding);
}

h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 1.7em;
    font-weight: 400;
}

.content {
    font-family: var(--font-primary);
    font-weight: 300;
    margin-top: 40px;
    font-size: 1.2em;
    letter-spacing: .5px;
    line-height: 1.3em;
    margin-bottom: 30px;
}

.content p {
    margin-bottom: 25px;
    text-align: justify;
}

.contactItem {
    height: 30px;
    line-height: 30px;
    color: var(--text-color);
}

.discord::before {
    display: inline-block;
    content: url('../assets/images/discord.svg');
    vertical-align: middle;
    margin-right: 13px;
}

.twitter::before {
    display: inline-block;
    content: url('../assets/images/twitter.svg');
    vertical-align: middle;
    margin-right: 13px;
}

.email::before {
    display: inline-block;
    content: url('../assets/images/email.svg');
    vertical-align: middle;
    margin-right: 13px;
}

@media screen and (max-width: 768px) {
    #wrapper {
        padding: 30px;
    }

    .content {
        font-size: 1.1em;
    }
}