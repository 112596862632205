.section {
    margin: 0px auto;
    max-width: var(--content-width);
}

@media screen and (max-width: 1024px) {
    .section {
        padding: 0px 30px 0px 30px;
    }

    .isMenu {
        padding: 0;
    }
}